<template>
   <div>
      <page-header :btnNew="items?.data?.length ? null : 'ACTIONS.NEW'"
                   pageTitle="CONFIG_CLICK_SIGN.INDEX_TITLE"
                   @newType="setRegisterModal"/>
      <div class="card" v-if="items && items?.data">
         <div class="row mw-100">
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
               <div class="font-weight-bolder mt-2 text-left" style="margin-left: 2.4rem">
                  {{ this.t('CONFIG_CLICK_SIGN.RESPONSIBLE') }}
               </div>
            </div>
            <div class="col-xl-4 col-lg-5 col-md-5 col-sm-5 col-5">
               <div class="font-weight-bolder mt-2 text-left">
                  {{ this.t('CONFIG_CLICK_SIGN.DEADLINE_TO_SIGNATURE') }}
               </div>
            </div>
            <div class="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-1 font-bold"
                 style="font-size: 1rem;">
            </div>
         </div>
         <div v-for="iten in items?.data" :key="iten.id" class="row">
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
               <div class="list-primary hide-text">
                  {{ iten.responsible_name }}
               </div>
            </div>
            <div class="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-5">
               <div class="list-primary hide-text">
                  {{ iten.deadline_to_signature }}
               </div>
            </div>
            <div class="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-1" style="margin-left: -1rem">
               <div class="text-right">
                  <a type="button" data-toggle="dropdown" aria-haspopup="true"
                     aria-expanded="false"
                     style="font-size: 20px" id="listDropdown" class="w-100">
                     <i class="icon-more_vert" title="Opções"></i>
                  </a>
                  <div class="dropdown-menu dropdown-menu-right dropdown-black">
                     <div class="dropdown-item pointer text-white"
                          data-toggle="modal" data-target="#customModalTwo" @click="setModalView(iten.id)">
                        <i class="icon-eye1 mr-2 font-15"/>{{ t('ACTIONS.VIEW') }}
                     </div>
                     <div class="dropdown-item pointer text-white"
                          data-toggle="modal" data-target="#customModalTwo"
                          @click="setModalEdit(iten.id)">
                        <i class="icon-pencil mr-2 font-15"/>{{ t('ACTIONS.EDIT') }}
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div class="card" v-if="items && !items?.data?.length">
         <div class="card-header"></div>
         <div class="card-body pt-0">
            <div class="text-center">
               <h5>{{ t('CONFIG_CLICK_SIGN.NONE_FOUND') }}</h5>
            </div>
         </div>
      </div>
      <pagination-component v-if="items?.data" :items="items" :to="'/loteamento'"
                            @changePage="index(filter, $event)"></pagination-component>

      <modal :modalLarge="true" :titleModal="modalTitle" v-if="modalChoose">
         <template v-slot:title>{{ modalTitle }}</template>
         <create-config-click-sign-componet @created="index()" :is-edit="isEdit" :is-view="isView" :iten-id="itenId"></create-config-click-sign-componet>
      </modal>
   </div>
</template>

<script>
import pageHeader from '../../components/layouts/pageHeader';
import modal from '../../components/modal';
import PaginationComponent from '../../components/layouts/PaginationComponent';
import ConfigClickSign from '../../services/ConfigClickSign';
import {useToast} from 'vue-toastification';
import {useI18n} from 'vue-i18n';
import createConfigClickSignComponet from './createConfigClickSignComponet';

export default {
   name: "indexConfigClickSignComponent",
   components: {
      pageHeader,
      modal,
      PaginationComponent,
      createConfigClickSignComponet
   },
   setup() {
      const toast = useToast();
      const {t} = useI18n();
      return {t, toast}
   },
   data() {
      return {
         items: {},
         itenId: null,
         isView: false,
         isEdit: false,
         modalTitle: '',
         modalChoose: null,
      }
   },
   mounted() {
      this.index();
   },
   methods: {
      index() {
         this.itenId = null;
         this.modalChoose = null;
         this.$store.commit('changeLoading', true);
         ConfigClickSign.index().then((resp) => {
            this.$store.commit('changeLoading', false);
            this.items = resp.data;
         }).catch(() => {
            this.$store.commit('changeLoading', false);
         })
      },
      setModalView(id){
         this.itenId = id;
         this.isEdit = false;
         this.isView = true;
         this.modalChoose = 1;
         this.modalTitle = this.t('CONFIG_CLICK_SIGN.VIEW');
      },
      setModalEdit(id){
         this.itenId = id;
         this.isEdit = true;
         this.isView = false;
         this.modalChoose = 1;
         this.modalTitle = this.t('CONFIG_CLICK_SIGN.EDIT');
      },
      setRegisterModal() {
         this.isEdit = false;
         this.isView = false;
         this.modalChoose = 1;
         this.modalTitle = this.t('CONFIG_CLICK_SIGN.CREATE');
      }
   }
}
</script>

<style scoped>

</style>